/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useState} from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Link } from "gatsby"
import styled from "styled-components"




const _handleSubmit = (email) => {
  return async (e) => {
  e.preventDefault();
  const result = await addToMailchimp(email)
  console.log(result);
  }
  // I recommend setting `result` to React state
  // but you can do whatever you want
}

const SubFooterContainer =  styled.footer` 

  width:100%;
  padding:40px;
  font-size:12px;
  text-align:center;
    .art-money {

      line-height:36px;
    }

  @media (min-width: 1000px) {
    padding:20px;
  &>div {
    text-align:left;
    display:flex;
    margin-left:auto;
    justify-content:space-between;
    margin-right:auto;
    padding-top:40px;
    max-width: 1400px;
    width:100%;
  }

  }
  
  ul {
    margin-bottom:0;
    li {
      display:inline-block;
      margin-right:20px;
      margin-bottom:0;
      cursor: pointer;
      padding: 0px 20px;
    }
  }
`     

const NewsletterContainer = styled.div` 
  background:linear-gradient(90deg, rgba(114,226,255,1) 0%, rgba(114,243,234,1) 100%);
display:flex;
flex-direction:row;

@media (max-width: 800px) { 
  flex-direction:column;
}
margin-left:auto;

justify-content:center;
margin-right:auto;
align-items:center;
padding:58px 20px;
margin-top:0px;
width:100%;

p {
  text-align:center;
  padding:20px 20px 0px 20px;
  font-size:18px;
  max-width: 1400px;
}

input {
  text-align:center;
  background:transparent;
  max-width: 400px;
  width: 100%;
  @media (max-width: 800px) { 
    margin:10px;
  }
}
`
const FooterContainer =  styled.footer` 
  background:linear-gradient(270deg, rgba(114,226,255,1) 0%, rgba(114,243,234,1) 100%);
  width:100%;
  padding: 80px 20px 20px 20px;
  text-align:center;

    .art-money {
        @media (max-width: 1000px) {
            text-align:center;
            padding:20px;
        }
         div {
          background-image:url('/artmoney.png');
          width:100px;  
          height:100px;
          background-size:contain;
    
        }
      line-height:20px;
    }
  @media (min-width: 1000px) {
    padding:10px;
  &>div {
    p {

      margin-left:20px;
    }
    text-align:left;
    display:flex;
    margin-left:auto;
    justify-content:space-between;
    margin-right:auto;
    padding-top:40px;
    max-width: 1400px;
    width:100%;
  }
  .art-money {

    display:flex;
    @media (max-width: 1000px) { 
      margin:20px;
    }
    

      margin-right:20px;
  }
  }
  
  ul {
    @media (max-width: 1000px) { 
        padding:20px;
    }

    li {
      @media (max-width: 1000px) {
        text-align:center; 
      }
      list-style-type:none;
      margin-right:20px;
      a:hover {
        text-decoration:underline;
      }
      cursor: pointer;
      padding: 0px 0px;
    }
  }
`     
    
function Footer() {
  
  const [email, setEmail] = useState(null);
     
  return (
  <div>
    
  <div className="newsletter" style={{
        "background":"rgba(114, 243, 255,0.6)",
    width:"100%"
  }}>
      <form onSubmit={  _handleSubmit(email)} style={{margin:0}}>
    <NewsletterContainer>
    
    <p>Please subscribe to our newsletter </p>

  <input type="text" placeholder="Enter your e-mail address" id="email" name="email" onChange={(e) => {setEmail(e.target.value)}}></input>
<button type="Submit">Subscribe</button>

  </NewsletterContainer>
  </form>
  </div>  
  <FooterContainer>
    <div>

    <div>
    <ul>
    <li><a href="https://www.facebook.com/franceskeevilart">Follow us on Facebook &#x27F6;</a ></li>
    <li><a href="http://www.instagram.com/franceskeevil">Follow us on Instagram &#x27F6;</a ></li>
    <li><a href="mailto:frances@franceskeevil.com.au">frances@franceskeevil.com.au</a ></li>
    <li>+61 411 821 550</li>
    </ul>

        </div>

        <div className={"art-money"}>
        
        <p style={{maxWidth:"1000px"}}>Frances Keevil is now offering Art Money:<br />A new, easier way to buy art.
<br />10 payments. 10 months. No interest.<br />Art Money is available from $675 to $50,000.<br />Apply online at <a href="http://artmoney.com.au">www.artmoney.com.au &#x27F6;</a></p>
        </div>
    </div>
  

    </FooterContainer>
    <SubFooterContainer>      <ul>
          <li><Link  to="/privacy">Privacy Policy</Link ></li>
          <li><Link to="/terms">Terms and Conditions</Link ></li>
          <li><Link to="/returns">Returns Policy</Link ></li>
                    <li><Link to="/shipping">Shipping Policy</Link ></li>
            <li>Website by B McLeod</li>
            <li>Copyright © 2021 Frances Keevil</li>
          
        </ul></SubFooterContainer>
  </div>
  )
}

Footer.defaultProps = {

}

Footer.propTypes = {

}

export default Footer
